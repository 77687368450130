<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li><a href="javascript:void(0);">Registration</a></li>
        </ul>
      </div>
    </div>
    <section class="section--registration">
      <div class="container">
        <h2 class="page__title">Registration</h2>
        <router-link :to="{ name: 'login' }">
          <p class="page__subtitle">Already have an account?</p>
        </router-link>
        <div class="registration__content">
          <div class="row">
            <div class="col-sm-8 offset-sm-2 card p-4">
              <div class="registration__info container">
                <h3 class="registration__title">Account Information</h3>
                <form @submit.prevent="onSubmit()">
                  <div class="form-row">
                    <div class="col-12 col-lg-6 form-group--block">
                      <label>First name: <span>*</span></label>
                      <input
                        class="form-control"
                        :class="{ 'is-invalid': $errors.has('first_name') }"
                        type="text"
                        placeholder="First name"
                        v-model="form.first_name"
                      />
                      <div
                        class="invalid-feedback"
                        v-show="$errors.has('first_name')"
                      >
                        {{ $errors.first("first_name") }}
                      </div>
                    </div>

                    <div class="col-12 col-lg-6 form-group--block">
                      <label>Last name<span>*</span></label>
                      <input
                        class="form-control"
                        :class="{ 'is-invalid': $errors.has('last_name') }"
                        type="text"
                        placeholder="Last name"
                        v-model="form.last_name"
                      />
                      <div
                        class="invalid-feedback"
                        v-show="$errors.has('last_name')"
                      >
                        {{ $errors.first("last_name") }}
                      </div>
                    </div>

                    <div class="col-sm-6 form-group--block">
                      <label>Phone number: <span>*</span></label>
                      <div class="input-group"
                          :class="{ 'is-invalid': $errors.has('phonecode') || $errors.has('phone') }"
                      >
                      <div class="input-group-addon">
                         <select class="form-control" style="width:100px"
                          :class="{ 'is-invalid': $errors.has('phonecode') }"
                          v-model="form.phonecode" >
                          <option value="" disabled>Select</option>
                          <option v-for="(country, index) in countriesWithPhonecode" :key="index" :value="country.phonecode">
                            ({{ country.phonecode }}) {{ country.name }}
                          </option>
                        </select>

                      </div>
                       
                        <input
                          class="form-control"
                          :class="{ 'is-invalid': $errors.has('phone') }"
                          type="number"
                          placeholder="Phone number"
                          v-model="form.phone"
                        />
                      </div>
                      <div
                        class="invalid-feedback"
                        v-show="$errors.has('phonecode')"
                      >
                        {{ $errors.first("phonecode") }}
                      </div>
                      <div
                        class="invalid-feedback"
                        v-show="$errors.has('phone')"
                      >
                        {{ $errors.first("phone") }}
                      </div>
                    </div>

                    <div class="col-sm-6 form-group--block">
                      <label>Email: <span>*</span></label>
                      <input
                        class="form-control"
                        :class="{ 'is-invalid': $errors.has('email') }"
                        type="text"
                        v-model="form.email"
                        placeholder="Email address"
                      />
                      <div
                        class="invalid-feedback"
                        v-show="$errors.has('email')"
                      >
                        {{ $errors.first("email") }}
                      </div>
                    </div>

                    <div class="col-sm-6 form-group--block">
                      <label>Password: <span>*</span></label>
                      <input
                        class="form-control"
                        :class="{ 'is-invalid': $errors.has('password') }"
                        type="password"
                        placeholder="Password"
                        v-model="form.password"
                      />
                      <div
                        class="invalid-feedback"
                        v-show="$errors.has('password')"
                      >
                        {{ $errors.first("password") }}
                      </div>
                    </div>

                    <div class="col-sm-6 form-group--block">
                      <label>Retype password: <span>*</span></label>
                      <input
                        class="form-control"
                        :class="{
                          'is-invalid': $errors.has('password_confirmation'),
                        }"
                        type="password"
                        placeholder="Retype password"
                        v-model="form.password_confirmation"
                      />
                      <div
                        class="invalid-feedback"
                        v-show="$errors.has('password_confirmation')"
                      >
                        {{ $errors.first("password_confirmation") }}
                      </div>
                    </div>

                    <div class="col-sm-12">
                      <button
                        class="btn btn-login"
                        type="submit"
                        :disabled="onSubmited"
                      >
                        Register
                      </button>
                    </div>
                     <div style="font-size:14px">
                      Already have an account? 
                      <router-link :to="{ name: 'login' }">
                        Login
                      </router-link>
                     </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        phonecode: '+94',
        phone: null,
      },
      onSubmited: false,
      emailGet: "",
    };
  },

  mounted() {
    this.fetchCountriesWithPhonecode();
  },

  computed: {
    ...mapGetters("country", ["countriesWithPhonecode"]),
  },

  methods: {
    ...mapActions("country", ["fetchCountriesWithPhonecode"]),

    onSubmit() {
      this.emailGet = this.form.email;
      this.onSubmited = true;
      this.$store
        .dispatch("auth/register", this.form)
        .then(() => {
          this.resetForm();
          this.$router.push({
            name: "verify-message",
            params: { email: this.emailGet },
          });
        })
        .catch(() => {
          this.onSubmited = false;
        });
    },

    resetForm() {
      var self = this; //you need this because *this* will refer to Object.keys below`

      //Iterate through each object field, key is name of the object field`
      Object.keys(this.form).forEach(function (key) {
        self.form[key] = null;
      });
      this.onSubmited = false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>